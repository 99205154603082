import React, {lazy, Suspense, useState} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import history from 'navigation/history';
import PrivateRoute from 'navigation/private-route';
import routes from 'navigation/routes';
import * as Authentication from 'utils/helpers/authentication-helper';
import HeaderOffComponent from 'components/header-off-component';
import PartnerCustomization from 'pages/main/partner-customization';
import PartnerDashboard from 'pages/main/partner-dashboard';
import PartnerReports from 'pages/main/partner-reports';
//import PartnerBilling from 'pages/main/partner-billing';
import MainComponent from 'components/main-component';
import RequestPassword from 'components/login/request-password-component';
import NewPassword from 'components/login/new-password-component';
import NotFound from 'pages/not-found';
import LoadingComponent from 'components/ui/loading/loading-component';
import {ToastContainer} from "react-toastify";
import PartnerHome from "../pages/main/home";
import SidebarTranslated from "../components/sidebar";
import PageUnavailableTranslated from "./page-unavailable";
import NewPartnerTranslated from "../pages/main/new-partner";
import NotificationTranslated from "../pages/main/notification";
import StoreApp from 'pages/store/store-app';
import AllPartnersTranslated from "../pages/main/all-partners";
import PartnerIMEITranslated from '../pages/main/partner-imei';
import AllKnoxDevicesTranslated from 'pages/main/all-knox-devices';

/* load some components in lazy fashion to improve performance spliting the chuncks */
const LoginComponentLazy = lazy(() => import('components/login/login-component'));

function ApplicationRouter() {
  const [navVisible, showNavbar] = useState(true);

  const isAuthAndNotExcludedPage =
    Authentication.isAuthenticated() &&
    !(location.pathname === routes.APP || location.pathname.startsWith(`${routes.APP}/`));

  return (
    <Router history={history}>
      {isAuthAndNotExcludedPage ? (
        <Suspense fallback={<LoadingComponent/>}>
          <SidebarTranslated visible={navVisible} show={showNavbar} history={history}/>
        </Suspense>
      ) : (
        <Suspense fallback={<LoadingComponent/>}>
          <HeaderOffComponent history={history}/>
        </Suspense>
      )}
      <MainComponent>
        <Suspense fallback={<LoadingComponent/>}>
          <Switch>
            <Route exact path={routes.ROOT} component={LoginComponentLazy}/>
            <Route exact path={routes.LOGIN} component={LoginComponentLazy}/>
            <Route exact path={routes.LOGOUT} component={LoginComponentLazy}/>
            <Route exact path={routes.REQUEST_CHANGE_PASSWORD} component={RequestPassword}/>
            <Route exact path={routes.APP} component={StoreApp}/>
            <Route exact path={routes.APP_PARTNER} component={StoreApp} />
            <Route exact path={routes.CHANGE_PASSWORD} component={NewPassword}/>
            <div className={!navVisible ? "page" : "page page-with-navbar blur"}>
              <PrivateRoute exact path={routes.HOME} component={PartnerHome}/>
              <PrivateRoute exact path={routes.CUSTOMIZATION} component={PartnerCustomization}/>
              <PrivateRoute exact path={routes.DASHBOARD} component={PartnerDashboard}/>
              <PrivateRoute exact path={routes.REPORTS} component={PartnerReports}/>
              <PrivateRoute exact path={routes.IMEI} component={PartnerIMEITranslated}/>
              <PrivateRoute exact path={routes.KNOX_DEVICES} component={AllKnoxDevicesTranslated}/>
              <PrivateRoute exact path={routes.UNAVAILABLE} component={PageUnavailableTranslated}/>
              <PrivateRoute exact path={routes.PARTNERS} component={AllPartnersTranslated}/>
              <PrivateRoute exact path={routes.NEW_PARTNER} component={NewPartnerTranslated}/>
              <PrivateRoute exact path={routes.NOTIFICATION} component={NotificationTranslated}/>
            </div>

            <Route component={NotFound}/>
          </Switch>
        </Suspense>
      </MainComponent>
      <ToastContainer/>
    </Router>
  );
}

export default ApplicationRouter;
