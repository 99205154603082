import React, {useEffect, useState} from 'react';
import CollateralService from "../../service/collateral-service";
import CollateralHelper from "../../utils/helpers/collateral-helper";
import LoadingComponent from 'components/ui/loading/little-loading-component';
import {withTranslation} from "react-i18next";
import ManageCollateralTranslated from "../ui/form/manage-collateral";
import FadeIn from "react-fade-in";
import Accordion from "../accordion/accordion";
import ToastHelper from "../../utils/helpers/toast-helper";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

function CollateralsTable(props) {
  const {t} = props
  const isDev = process.env.REACT_APP_ENV === 'dev'
  const [deviceId, setDeviceId] = useState("")
  const [productItemId, setProductItemId] = useState(null)
  const [userId, setUserId] = useState(null)
  const [executorItemId, setExecutorItemId] = useState(null)
  const [collateralId, setCollateralId] = useState(null)
  const [loading, setLoading] = useState("")
  const [items, setItems] = useState([])
  const [collateral, setCollateral] = useState()
  const [totalNumber, setTotalNumber] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [manage, setManage] = useState(false)
  let [product, setProduct] = useState(t('account_info.all_products'))
  const pages = Math.ceil(totalNumber / itemsPerPage)

  const itemsOption = [
    "3", "5", "10", "20"
  ];

  const productOption = [
    t('account_info.all_products'), t('account_info.hardware_lock')
  ];

  const changeQuantity = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage)
  }

  const changeProduct = (product) => {
    setProduct(product)
  }

  useEffect(() => {
    const fetchData = async () => {
      let result;

      switch(product) {
        case t('account_info.hardware_lock'):
          result = await CollateralService.findAllPolicies(currentPage, itemsPerPage, 'DESC')
          break;
        default:
          result = await CollateralService.findCollaterals(currentPage, itemsPerPage, 'DESC')
          break;
      }

      setItems(result.data.content)
      setTotalNumber(result.data.totalElements)

      setLoading("")
    }
    fetchData()
  }, [currentPage, itemsPerPage, collateral, product, t])

  function isNumeric(num) {
    if (num === '' || num === null) {
     return !isNaN(num) && num !== null && num !== '';
    }
    return !isNaN(num)
  }

  function searchByProductItemId(event) {
    event.preventDefault()

    if (isNumeric(productItemId)) {
      ToastHelper.showLoading(CollateralService.findCollateralByProductItemId(productItemId, 0, 5, 'DESC'), {
        error: {
          message: t('toast_helper.search_device_error'),
          notFound: t('toast_helper.not_found')
        },
      })
        .then(response => {
          if (response.data.totalElements === 0) {
            ToastHelper.showInfo(t('toast_helper.not_found'))
          } else {
            setItems(response.data.content)
            setTotalNumber(response.data.totalElements)
          }
        })
        .catch(e => {
          setCollateral(null)
        });
    } else {
      ToastHelper.showInfo(t('toast_helper.valid_input'))
    }
  }

  /* FUNCTION IMEI*/
  function searchExecutorItemId(event) {
    event.preventDefault();

    if (isNumeric(executorItemId)) {
      setLoading(true);
      CollateralService.findByExecutorItemId(executorItemId)
        .then(response => {
          if (response.data.totalElements === 0) {
            ToastHelper.showInfo(t('toast_helper.not_found'));
          } else {

            const itemsWithIMEI = response.data.content.map(async item => {
              const imeiResponse = await CollateralService.findByExecutorItemId(executorItemId);
              return {
                ...item,
                imei: imeiResponse.data.imei || "N/A"
              };
            });

            Promise.all(itemsWithIMEI).then(results => {
              setItems(results);
              setTotalNumber(response.data.totalElements);
            });
          }
        })
        .catch(error => {
          ToastHelper.showError(t('toast_helper.search_device_error'));
          setItems([]);
          setTotalNumber(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      ToastHelper.showInfo(t('toast_helper.valid_input'));
    }
  }


  function searchByCollateralId(event) {
      event.preventDefault()

      if (isNumeric(collateralId)) {
        ToastHelper.showLoading(CollateralService.findCollateralById(collateralId), {
          error: {
            message: t('toast_helper.search_device_error'),
            notFound: t('toast_helper.not_found')
          },
        })
          .then(response => {
            if (response.data.totalElements === 0) {
              ToastHelper.showInfo(t('toast_helper.not_found'))
            } else {
              setItems(response.data.content)
              setTotalNumber(response.data.totalElements)
            }
          })
          .catch(e => {
            setCollateral(null)
          });
      } else {
        ToastHelper.showInfo(t('toast_helper.valid_input'))
      }
    }

    //function UserID
      function searchByUserId(event) {
        event.preventDefault();
        if (isNumeric(userId)) {
          setLoading(true);
          CollateralService.findCollateralByUserId(userId)
            .then(response => {
              if (response.data.totalElements === 0) {
                ToastHelper.showInfo(t('toast_helper.not_found'));
              } else {
                setItems(response.data.content);
                setTotalNumber(response.data.totalElements);
              }
            })
            .catch(error => {
              ToastHelper.showError(t('toast_helper.search_device_error'));
              setItems([]);
              setTotalNumber(0);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          ToastHelper.showInfo(t('toast_helper.valid_input'));
        }
      }

  function first(event) {
    setCurrentPage(Number(event.target.value))
    setLoading("first")
  }

  function previous() {
    setCurrentPage(currentPage - 1)
    setLoading("previous")
  }

  function next() {
    setCurrentPage(currentPage + 1)
    setLoading("next")
  }

  function last(event) {
    setCurrentPage(Number(event.target.value))
    setLoading("last")
  }

  function validateDeviceId() {
    if (!deviceId) {
      ToastHelper.showWarning(t('toast_helper.update_due_date_error'));

      this.setState({
        error: true,
        errorMessage: t('toast_helper.valid_device_id')
      });
      return false;
    }
    return true;
  }

  function findCollateral(event) {
    event.preventDefault()

    if (validateDeviceId()) {
      ToastHelper.showLoading(CollateralService.findByExecutorItemId(deviceId), {
        pending: t('toast_helper.search_device'),
        error: {
          message: t('toast_helper.search_device_error'),
          notFound: t('toast_helper.not_found')
        },
        success: t('toast_helper.device_found')
      })
        .then(response => {
          activateCollateral()
          setCollateral(response.data)
        })
        .catch(e => {
          setCollateral(null)
        });
    }
  }

  function activateCollateral() {
    if (validateDeviceId()) {
      ToastHelper.showLoading(CollateralService.activateCollateral(deviceId), {
        success: t('toast_helper.device_activated'),
        pending: t('toast_helper.trying_activate'),
        error: {
          notFound: t('toast_helper.not_found'),
          message: t('toast_helper.error_on_activating')
        },
      })
        .then(response => {
          setCollateral(response.data)
        })
        .catch(e => {
          setCollateral(null)
        });
    }
  }

  function cleanFilters() {
    setCollateralId("")
    setProductItemId("")
    setCollateral(null)
    setUserId("")
  }

  return (<>
      {manage === false ?
        <FadeIn>
          <p className="page-title">{t('home.all_devices')}</p>
          <div className="filter-container">
            <div className="portal-custom-select">
              <div className="label-container">
                <p className="silver label-same-size">{t('account_info.devices_per_page')}</p>
              </div>
              <Dropdown className="form-select" options={itemsOption} onChange={(event) => changeQuantity(event.value)} value={itemsOption[0]} placeholder="Select an option" />
            </div>

            <div className="portal-custom-select">
              <div className="label-container">
                <p className="silver label-same-size">{t('account_info.product')}</p>
              </div>
              <Dropdown className="form-select" options={productOption} onChange={(event) => changeProduct(event.value)} value={productOption[0]} placeholder="Select an option" />
            </div>

            <div className="two-col">
              <div className="form-input">
                <input type="number " id="" className="invisible-input" placeholder={t('account_info.id')}
                       value={collateralId} onChange={
                  (e) => {
                    setCollateralId(e.target.value)
                  }}
                />
                <button className="invisible-button" onClick={(event) => searchByCollateralId(event)}><i
                  className="bi bi-search bi-accent"></i></button>
              </div>
            </div>

            <div className="two-col">
              <div className="form-input">
                <input type="number " id="" className="invisible-input" placeholder={t('account_info.product_id')}
                       value={productItemId} onChange={
                  (e) => {
                    setProductItemId(e.target.value)
                  }}
                />
                <button className="invisible-button" onClick={(event) => searchByProductItemId(event)}><i
                  className="bi bi-search bi-accent"></i></button>
              </div>
            </div>

            <div className="two-col">
                          <div className="form-input">
                            <input type="number " id="" className="invisible-input" placeholder={t('account_info.user_id')}
                                   value={userId} onChange={
                              (e) => {
                                setUserId(e.target.value)
                              }}
                            />
                            <button className="invisible-button" onClick={(event) => searchByUserId(event)}><i
                              className="bi bi-search bi-accent"></i></button>
                          </div>
                        </div>

            {collateralId || productItemId || userId ?
              <button type="submit" onClick={() => cleanFilters()}
                      className="clear-filters-button">{t('home.clear_filters')} <i
                className="bi bi-x"></i></button>
              :
              null
            }

          </div>

          <div className="table-container">
            <table className="custom-table">
              <thead className="table-header">
              <tr>
                {/* COLLATERAL ID */}
                <td>
                  <div className="align-div">{t('account_info.id')}</div>
                </td>
                {/* IMEI */}
                <td>
                  <div className="align-div">{t('knox_devices.imei')}</div>
                </td>

                {/* STATUS */}
                <td>
                  <div className="align-div">{t('account_info.status')}</div>
                </td>

                {/* ACTIVATE AT */}
                <td>
                  <div className="align-div">{t('account_info.activated')}</div>
                </td>

                {/* DUE DATE */}
                <td>
                  <div className="align-div">{t('account_info.dueDate')}</div>
                </td>

                {/* RELEASED AT */}
                <td>
                  <div className="align-div">{t('account_info.released_at')}</div>
                </td>

                {/* DEVICE MODEL */}
                <td>
                  <div className="align-div">{t('account_info.manufacturer_model')}</div>
                </td>

                {/* CLIENT INTERN ID */}
                <td>
                  <div className="align-div">{t('account_info.product_id')}</div>
                </td>

                {/* CLIENT NAME */}
                {isDev && (
                  <td>
                    <div className="align-div">PARTNER</div>
                  </td>
                )}

                {/* USER ID */}
                <td>
                  <div className="align-div">{t('account_info.user_id')}</div>
                </td>

                {/* API AUTH TOKEN */}
                {isDev && (
                  <td>
                    <div className="align-div">API</div>
                  </td>
                )}
              </tr>
              </thead>
              <tbody className="custom-tbody">

              {items.map(item => {
                return <tr key={item.id} onClick={() => setCollateral(item)}
                           onDoubleClick={() => setManage(item)}>
                  {/* COLLATERAL ID */}
                  <td>
                    <div className="align-div">{CollateralHelper.id(item) || "N/A"}</div>
                  </td>
                  {/* IMEI */}
                  <td>
                    <div className="align-div">{CollateralHelper.executorItemId(item)  || "N/A"}</div>
                  </td>

                  {/* STATUS */}
                  <td>
                    <div className="align-div">{CollateralHelper.state(item)}</div>
                  </td>

                  {/* ACTIVATE AT */}
                  <td>
                    <div className="align-div">{CollateralHelper.activatedAt(item) || "N/A"}</div>
                  </td>

                  {/* DUE DATE */}
                  <td>
                    <div className="align-div">{CollateralHelper.lockIsScheduled(item.dueDate) ? CollateralHelper.dueDate(item) : "N/A"}</div>
                  </td>

                  {/* RELEASED AT */}
                  <td>
                    <div className="align-div">{CollateralHelper.releasedAt(item) || "N/A"}</div>
                  </td>

                  {/* DEVICE MODEL */}
                  <td>
                    <div
                      className="align-div">{CollateralHelper.deviceBrand(item)} {CollateralHelper.deviceModel(item)}</div>
                  </td>

                  {/* CLIENT INTERN ID */}
                  <td>
                    <div className="align-div">{CollateralHelper.productItemId(item) || "N/A"}</div>
                  </td>

                  {/* CLIENT NAME */}
                  {isDev && (
                    <td>
                      <div className="align-div">{"N/A"}</div>
                    </td>
                  )}

                  {/* USER ID */}
                  <td>
                    <div className="align-div">{CollateralHelper.userId(item) || "N/A"}</div>
                  </td>


                  {/* API AUTH TOKEN */}
                  {isDev && (
                    <td>
                      <div className="align-div">{"N/A"}</div>
                    </td>
                  )}

                  {/* GO TO MANAGE YOUR COLLATERAL */}
                  <td onClick={() => setManage(item)}>
                    <i className="bi bi-chevron-right bi-accent"></i>
                  </td>
                </tr>
              })}
              </tbody>
            </table>
          </div>

          <div className="pagination-container">
            <div className="pagination-bar">
              <button value={0} onClick={(event) => {
                first(event)
              }} disabled={currentPage === 0}>
                {loading === "first" ? <LoadingComponent pink={true}/> : null}
                {t('account_info.first')}
              </button>

              <button value={currentPage} onClick={() => {
                previous()
              }} disabled={currentPage === 0}>
                {loading === "previous" ? <LoadingComponent pink={true}/> : false}
                {t('account_info.previous')}
              </button>

              <button className="currentPage" value={currentPage}>{currentPage + 1}</button>

              <button value={currentPage} onClick={() => {
                next()
              }} disabled={currentPage === pages - 1}>
                {loading === "next" ? <LoadingComponent pink={true}/> : false}
                {t('account_info.next')}
              </button>

              <button value={pages - 1} onClick={(event) => {
                last(event)
              }} disabled={currentPage === pages - 1}>
                {loading === "last" ? <LoadingComponent pink={true}/> : false}
                {t('account_info.last')}
              </button>
            </div>
            <div className="pagination-numbers">
              {t('account_info.total_devices')} {totalNumber}
            </div>
          </div>
        </FadeIn> : null}
      {manage ?
        <FadeIn>
          <ManageCollateralTranslated collateral={manage}/>
          <p className="page-title accent pointer" onClick={() => setManage(false)}><i
            className="bi bi-chevron-left"></i> {t('manage.back_home')}</p>
        </FadeIn>
        : null
      }
    </>
  );
}

const CollateralsTableTranslated = withTranslation()(CollateralsTable)
export default CollateralsTableTranslated
